import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Typography,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getFees, addFees, deleteFees, updateFees } from '../actions/fees';
import { useMediaQuery } from 'react-responsive';

const Fees = () => {
  const { fees } = useSelector((state) => state.fees);
  const { authData } = useSelector((state) => state.auth);
  const isMobile = useMediaQuery({ maxWidth: 770 });

  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [updatedFee, setUpdatedFee] = useState({});
  const [classFee, setClassFee] = useState({ className: '', classFees: '' });

  useEffect(() => {
    dispatch(getFees());
  }, [dispatch]);

  const handleUpdateFee = (fee) => {
    setUpdatedFee(fee)
    setOpenUpdate(true)
  };

  const handleUpdateSave = (fee) => {
    dispatch(updateFees(fee._id, fee))
    setOpenUpdate(false)
  }

  const handleAddFee = () => {
    setOpen(true);
  };

  const handleSaveFee = () => {
    dispatch(addFees(classFee));
    setClassFee({ className: '', classFees: '' });
    setOpen(false);
  };

  const handleDeleteFee = (_id) => {
    dispatch(deleteFees(_id));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '37px', fontWeight: 'bold', color: 'navy', paddingTop: '2%' }}>
        Fee Structure
      </h1>
      <TableContainer component={Paper} style={{ margin: 'auto', marginTop: '20px', marginBottom: '70px',width: isMobile ? '90%' : '40%'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Class</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Fees</TableCell>
              {authData?.role === '0' && <TableCell style={{ fontWeight: 'bold' }} >Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {fees.map((fee, index) => (
              <TableRow key={index}>
                <TableCell>{fee.className}</TableCell>
                <TableCell>₹ {fee.classFees}</TableCell>
                {authData?.role === '0' && <TableCell>
                  <Button variant='contained' style={{margin:'5px'}} onClick={() => handleUpdateFee(fee)}>Update</Button>
                  <Button variant='contained' color='error' style={{margin:'5px'}} onClick={() => handleDeleteFee(fee._id)}>Delete</Button>
                </TableCell>}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {authData?.role === '0' && <Button
        variant="contained"
        color="primary"
        onClick={handleAddFee}
        style={{ marginBottom: '20px' }}
      >
        Add Class Fee
      </Button>}

      <Dialog open={open || false} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Add Class Fee</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="className"
            label="Class Name"
            type="text"
            fullWidth
            variant="standard"
            value={classFee.className}
            onChange={(e) => setClassFee({ ...classFee, className: e.target.value })}
          />
          <TextField
            margin="dense"
            id="classFees"
            label="Class Fees"
            type="text"
            fullWidth
            variant="standard"
            value={classFee.classFees}
            onChange={(e) => setClassFee({ ...classFee, classFees: e.target.value })}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleSaveFee()
                }
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSaveFee}>Save</Button>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openUpdate || false} onClose={() => setOpenUpdate(false)} fullWidth>
        <DialogTitle>Update Class Fee</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="className"
            label="Class Name"
            type="text"
            fullWidth
            variant="standard"
            value={updatedFee.className}
            onChange={(e) => setUpdatedFee({ ...updatedFee, className: e.target.value })}
          />
          <TextField
            margin="dense"
            id="classFees"
            label="Class Fees"
            type="text"
            fullWidth
            variant="standard"
            value={updatedFee.classFees}
            onChange={(e) => setUpdatedFee({ ...updatedFee, classFees: e.target.value })}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  handleUpdateSave(updatedFee)
                }
              }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleUpdateSave(updatedFee)}>Update</Button>
          <Button onClick={() => setOpenUpdate(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Fees;
