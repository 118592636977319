import { combineReducers } from "redux";
import homework from './homework.js'
import gallery from "./gallery.js";
import staff from "./staff.js";
import fees from "./fees.js";
import carousal from "./carousal.js";
import auth from "./auth.js";
// import {
//     FLUSH, PAUSE,
//     PERSIST, persistReducer, PURGE,
//     REGISTER, REHYDRATE
//   } from 'redux-persist';
//   import storage from 'redux-persist/lib/storage';


export default combineReducers({
    homework:homework,
    gallery:gallery,
    staff:staff,
    fees:fees,
    carousal:carousal,
    auth:auth,
})