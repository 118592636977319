import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const isMobile = window.innerWidth <= 760; // Adjust the breakpoint as needed

  const listItemStyle = {
    listStyleType: 'none', // Remove bullet points
    margin: 0,
    padding: 0,
    textAlign: isMobile ? 'center' : 'left', // Center-align text if isMobile is true
  };

  const listItemLinkStyle = {
    cursor: 'pointer',
    textDecoration: 'none',
    color: '#fffbc4',
  };

  const customBulletStyle = {
    content: '"›"', // You can adjust the content here to customize the bullet
    marginRight: '8px', // Adjust the spacing between the bullet and text
    color: '#007BFF', // Adjust the color of the bullet
  };

  return (
    <footer style={{ backgroundColor: '#2C2D2E', padding: '20px' }}>
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="footer-content">
              <h3 style={{ color: '#fffbc4', textAlign:isMobile?'center':'left' }}>Address</h3>
              <p style={{ color: '#fffbc4', textAlign: isMobile ? 'center' : 'left' }}>
                St. Lawrence High School <br />
                New Road, Chanchalguda,<br/>
                Hyderabad-500024.
              </p>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="footer-content">
              <h3 style={{ color: '#fffbc4', textAlign:isMobile?'center':'left' }}>Links</h3>
              <ul style={listItemStyle}>
                <li>
                  <Link style={listItemLinkStyle} to="/aims-objectives">
                    Aims & Objectives
                  </Link>
                </li>
                <li>
                  <Link style={listItemLinkStyle} to="/fees">
                    Fee Structure
                  </Link>
                </li>
                <li>
                  <Link style={listItemLinkStyle} to="vision-mission">
                    Vision & Mission
                  </Link>
                </li>
                <li>
                  <Link style={listItemLinkStyle} to="/curriculum">
                    Curriculum
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12">
            <div className="footer-content">
              <h3 style={{ color: '#fffbc4', textAlign:isMobile?'center':'left' }}>Follow Us</h3>
              <ul style={listItemStyle}>
                <li>
                  <Link style={listItemLinkStyle} to="#">
                    Terms & Conditions
                  </Link>
                </li>
                <li>
                  <Link style={listItemLinkStyle} to="#">
                    Refunds & Cancellations
                  </Link>
                </li>
                <li>
                  <Link style={listItemLinkStyle} to="#">
                    Privacy Statement
                  </Link>
                </li>
                {/* <li>
                  <Link style={listItemLinkStyle} to="#">
                    <i className="fab fa-facebook"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
