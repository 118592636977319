import axios from 'axios';

const API = axios.create({baseURL: 'https://api.stlawrencehighschool.com/'})

API.interceptors.request.use((req)=>{
    if(localStorage.getItem('profile'))
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`

        return req;
})

// Homework
export const getHomeworks = (className) => API.get(`/homework/${className}`);
export const createHomework = (data) => API.post(`/homework`,data);
export const updateHomework = (id, data) => API.patch(`/homework/${id}`,data);
export const deleteHomework = (id) => API.delete(`/homework/${id}`);
export const deleteHomeworkImage = (_id, public_id) => API.delete(`/homework/image/${_id}/${public_id}`);

// Gallery
export const getImages = (page) => API.get(`/gallery/${page}`);
export const createImages = (images) => API.post('/gallery',{images});
export const deleteImage = (image_id) => API.delete(`/gallery/${image_id}`);

// Auth
export const login = (authData) => API.post('/auth/login',authData)
export const signup = (authData) => API.post('/auth/signup',authData)
export const deleteAccount = (email) => API.delete(`/auth/${email}`)

// Staff
export const getStaff = () => API.get('/staff')
export const addStaff = (staffMember) => API.post('/staff',{staffMember})
export const deleteStaff = (id) => API.delete(`/staff/${id}`)

// Fees
export const getFees = () => API.get('/fees')
export const addFees = (data) => API.post('/fees',data)
export const updateFees = (id, data) => API.patch(`/fees/${id}`,data)
export const deleteFees = (id) => API.delete(`/fees/${id}`)

// Carousal
export const getCarousalImages = () => API.get(`/carousal`);
export const createCarousalImage = (image) => API.post('/carousal',{image});
export const deleteCarousalImage = (image_id) => API.delete(`/carousal/${image_id}`);
