import React from 'react'

const AdmissionProcess = () => {
    const headerStyle = {
      fontSize: '37px', // Reduced font size
      fontWeight: 'bold', // Bold font
      color: 'navy', // Navy blue color
      paddingTop: '5%',
    };
  
    return (
      <>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="text-center pt-3" style={headerStyle}>
                  ADMISSION PROCESS
                </h1>
                <div className="aims-content">
                <p className="pt-3" style={{ textAlign: 'justify' }}>
  <ol>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      Admission to classes, I to X will be done in March/April as per the availability of seats and based on merit. Special preference will be given to talented students in Sports and Games, Cultural and Literary activities. The school being a Minority Institution, Minority children are given special consideration.
    </li>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      For the information of the public, a vacancy chart will be displayed on the school Bulletin Board in the month of January/February.
    </li>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      Special attention must be paid while entering the name and date of birth of the child. Subsequent change in the date of birth or name will not be permitted.
    </li>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      Admission is finalized after due processing and payment of the prescribed fees, which once paid is not refundable.
    </li>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      The school DOES NOT COLLECT ANY DONATIONS whatsoever for admissions either directly or indirectly. Therefore, the management should not be blamed if anyone collects a donation with the promise of securing admission in the school. However, the management shall appreciate it if such specific cases are brought to their notice in WRITING so that suitable action may be taken against the guilty.
    </li>
  </ol>

  <strong>WITHDRAWALS</strong>
  <ul>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      A month&rsquo;s notice is required for a student to withdraw from the school, failing which a month&rsquo;s fee must be paid.
    </li>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      Those who leave the school in the month of March must pay their fee for the months of April &amp; May.
    </li>
    <li style={{ marginBottom: '10px', paddingLeft: '20px' }}>
      Notice of leaving the school must be given before the beginning of the holidays or else the fee for the first month of the following term will have to be paid, i.e., June.
    </li>
  </ul>
</p>


                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  };

export default AdmissionProcess
