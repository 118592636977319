import * as api from '../api'
import { CREATE_CAROUSAL_IMAGE, DELETE_CAROUSAL_IMAGE, END_LOADING_CAROUSAL_IMAGE, FETCH_ALL_CAROUSAL_IMAGES, START_LOADING_CAROUSAL_IMAGE } from '../constants/carousal';


export const getCarousalImages = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING_CAROUSAL_IMAGE})
        const {data} = await api.getCarousalImages();
        dispatch({type:FETCH_ALL_CAROUSAL_IMAGES,payload:data});
        dispatch({type:END_LOADING_CAROUSAL_IMAGE})
    }
    catch(error)
    {
        console.log(error);
    }
}

export const createCarousalImage = (image) => async(dispatch) => {
    try {
        // console.log(images)
        const {data} = await api.createCarousalImage(image);
        dispatch({type:CREATE_CAROUSAL_IMAGE,payload:data.data});
    } catch (error) {
        console.log(error)
    }
} 

export const deleteCarousalImage = (image) => async(dispatch) => {
    try {
            await api.deleteCarousalImage(image._id)
            dispatch({type:DELETE_CAROUSAL_IMAGE,payload:image})
    } catch (error) {
        console.log(error)
    }
}