import React from 'react';
import Paper from '@mui/material/Paper';

const SchoolTimings = () => {
  const mainHeadingStyle = {
    fontSize: '37px',
    fontWeight: 'bold',
    color: 'navy',
    paddingTop: '5%',
    width: '100%',
  };

  const headingStyle = {
    fontSize: '20px', // Decreased font size
    fontWeight: 'bold', // Bold font
  };

  const paragraphStyle = {
    fontSize: '16px',
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <h1 className="text-center" style={mainHeadingStyle}>
            SCHOOL TIMINGS
          </h1>
          <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
            <div className="timing-content mb-5">
              <h4 style={headingStyle}>Regular Timings</h4>
              <p className="pt-2" style={paragraphStyle}>
                Classes Nursery, L.K.G & U.K.G: 8.40 a.m. to 12.30 p.m.
              </p>
              <p style={paragraphStyle}>Class I to X : 8.40 a.m. to 3.00 p.m.</p>
              <h4 className="pt-4" style={headingStyle}>
                Friday
              </h4>
              <p className="pt-2" style={paragraphStyle}>
                Classes Nursery, L.K.G & U.K.G: 8.40 a.m. to 12.30 p.m.
              </p>
              <p style={paragraphStyle}>Class I to X : 8.40 a.m. to 12.30 p.m.</p>
              <Paper
  elevation={3}
  style={{
    background: 'lightgrey', // Set the background color
    padding: '20px', // Adjust the padding as needed
    maxWidth: '60vw', // Limit the maximum width
    margin: '0 auto', // Center align
    textAlign:'center'
  }}
>
  <h5 style={{fontWeight:'bold'}} >
    (Students must be present in the school premises by 8.30 a.m. on all working days)
  </h5>
</Paper>

              <h5 className="pt-5" style={headingStyle}>
                School Office Timings for Parents & Guardians
              </h5>
              <p style={paragraphStyle}>9.00 a.m. to 1.10 p.m.</p>
              <h5 style={headingStyle}>
                No school business will be undertaken on holidays.
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default SchoolTimings;
