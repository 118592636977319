import React from 'react';

const VisionMission = () => {
  const mainHeadingStyle = {
    fontSize: '37px', // Reduced font size
    fontWeight: 'bold', // Bold font
    color: 'navy', // Navy blue color
    paddingTop: '5%',
    width:'100%'
  };

  const headingStyle = {
    fontSize: '24px', // Reduced font size
    fontWeight: 'bold', // Bold font
  };

  const paragraphStyle = {
    fontSize: '16px', // Increased font size
  };

  return (
    <section>
      <div className="container">
        <div className="row">
          <h1 className="text-center " style={mainHeadingStyle}>
            OUR VISION
          </h1>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="vission-content pt-5">
              <h3 style={headingStyle}>VISION</h3>
              <p style={paragraphStyle}>
                To train the students in the ‘Gracious art of living’, making
                the whole learning process a joy-filled experience as it aims
                at the holistic and integral formation of each of them.
              </p>
            </div>
            <div className="vission-content pt-5">
              <h3 style={headingStyle}>MISSION</h3>
              <p style={paragraphStyle}>
                St. Lawrence High School strives at moulding minds to carry this
                quest: For the creation of a just and equitable social order for
                the creation of a sustainable global society founded on respect
                for nature, universal human rights, economic justice, and a
                culture of peace.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VisionMission