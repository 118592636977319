import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from 'react-redux';
import { createCarousalImage, deleteCarousalImage, getCarousalImages } from '../actions/carousal';
import { useMediaQuery } from 'react-responsive';


const CarouselComponent = () => {
  const carouselButtonsStyle = {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    zIndex: '100'
  };

  const buttonStyle = {
    margin: '0 0',
  };

  const { authData } = useSelector(state => state.auth)
  const dispatch = useDispatch()
  const { carousalImages } = useSelector(state => state.carousal)
  const [images, setImages] = useState([{}])
  const [imageURL, setImageURL] = useState('')
  const [imageDelete, setImageDelete] = useState('')
  const [imageBase64, setImageBase64] = useState('')
  const [openDialog, setOpenDialog] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)

  const isMobile = useMediaQuery({ maxWidth: 770 })


  useEffect(() => {
    dispatch(getCarousalImages())
  }, [])
  useEffect(() => {
    setImages(carousalImages)
  }, [carousalImages])

  const toDataURL = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  const handleFileSelect = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Create an URL for the selected image
      const imageURL = URL.createObjectURL(file);

      // Use the toDataURL function to convert the image URL to base64
      toDataURL(imageURL, (base64Data) => {
        // Now you have the base64 data for the selected image
        setImageURL(imageURL);
        setImageBase64(base64Data);
        setOpenDialog(true);
      });
    }
  };

  const addImage = () => {
    dispatch(createCarousalImage(imageBase64))
    setImageURL('')
    setImageBase64('')
    setOpenDialog(false)
  }

  const handleDeleteClick = (image) => {
    setImageDelete(image)
    setOpenDelete(true)
  }

  const deleteImage = () => {
    dispatch(deleteCarousalImage(imageDelete))
    setOpenDelete(false)
  }

  return (
    <>
      <div id="carouselExampleControls" className="carousel slide" data-ride="carousel" >
        <div className="carousel-inner">
          {
            images?.map((image, index) => (
              <div className={`carousel-item ${index === 0 ? 'active' : ''}`}>
                <img className="d-block w-100" style={{ width: '100%', maxHeight: isMobile ? '15vh' : '60vh' }} src={image?.url} alt="First slide" />
                {/* Add button (positioned on the right side) */}
                {authData?.role === '0' && <div style={carouselButtonsStyle}>

                  <input
                    type="file"
                    accept="image/*" // Accept only image files
                    style={{ display: 'none' }}
                    id={`carousal-image-upload`}
                    onChange={(e) => { handleFileSelect(e) }}
                  />
                  <label htmlFor={`carousal-image-upload`}  >

                    <IconButton color="primary" component='span' style={buttonStyle}>
                      <AddIcon />
                    </IconButton>

                  </label>


                  {/* Delete button (positioned on the right side) */}
                  <IconButton color="error" style={buttonStyle} onClick={()=>handleDeleteClick(image)}>
                    <DeleteOutlineIcon />
                  </IconButton>
                </div>}
              </div>
            ))
          }
        </div>
        <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
          <span className="carousel-control-next-icon" ariahidden="true"></span>
          <span className="sr-only">Next</span>
        </a>


      </div>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth >
        <DialogTitle>
          This is the selected image
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '5px', // Add margin to create spacing
                width: 'calc(100% - 10px)', // Set width for 2 images per row
                // You can adjust the width percentage and margin as needed
              }}
            >
              <img
                style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                src={imageURL}
                alt=""
              />
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => addImage()}>Save</Button>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete} onClose={() => setOpenDelete(false)} fullWidth >
        <DialogTitle>
          Are you sure you want to delete this image?
        </DialogTitle>
        <DialogContent>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                margin: '5px', // Add margin to create spacing
                width: 'calc(100% - 10px)', // Set width for 2 images per row
                // You can adjust the width percentage and margin as needed
              }}
            >
              <img
                style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                src={imageDelete.url}
                alt=""
              />
            </div>

          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => deleteImage()}>Delete</Button>
          <Button onClick={() => setOpenDelete(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CarouselComponent;
