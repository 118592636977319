import { ADD_STAFF, DELETE_STAFF, END_LOADING_STAFF, GET_STAFF, START_LOADING_STAFF } from "../constants/staff";



export default (state = { isLoading: false, staff: [{}] }, action) => {
    switch (action.type) {
        case START_LOADING_STAFF:
            return {
                ...state,
                isLoading: true
            }
        case END_LOADING_STAFF:
            return {
                ...state,
                isLoading: false
            }
        case DELETE_STAFF:
            return {
                ...state,
                staff: state.staff.filter((staff) => staff._id !== action.payload)
            };
        case GET_STAFF:
            // console.log(action.payload)
            return {
                ...state,
                staff: action.payload.data
            };
        case ADD_STAFF:
            return {
                ...state,
                staff: [...state.staff, action.payload]
            };
        default:
            return state;
    }
}
