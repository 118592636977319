import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  CircularProgress,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useMediaQuery } from 'react-responsive';
import { useDispatch, useSelector } from 'react-redux';
import { deleteStaff, getStaff, addStaff } from '../actions/staff';

const Staff = () => {
  const isMobile = useMediaQuery({ maxWidth: 770 });
  const { authData } = useSelector((state) => state.auth);
  const { staff, isLoading } = useSelector((state) => state.staff);
  const dispatch = useDispatch()


  const [rows, setRows] = useState([{}]);
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [teacher, setTeacher] = useState({ teacherName: '', qualifications: '' });
  const [teacherDelete, setTeacherDelete] = useState({});

  useEffect(() => {
    dispatch(getStaff())
  }, [])
  useEffect(()=>{
    setRows(staff)
  },[staff])

  const handleAddRow = () => {
    setOpen(true)
  };

  const handleDeleteRow = (teacher) => {
    setTeacherDelete(teacher)
    setOpenDelete(true)
  };
  const handleDelete = () => {
    dispatch(deleteStaff(teacherDelete._id))
    setOpenDelete(false)
  }

  const handleSave = () => {
    dispatch(addStaff(teacher))
    setTeacher({ teacherName: '', qualifications: '' })
    setOpen(false)
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: '37px', fontWeight: 'bold', color: 'navy', paddingTop: '2%' }}>
        Our Staff
      </h1>
      <TableContainer
        component={Paper}
        style={{ margin: 'auto', marginTop: '20px', marginBottom: '70px', width: isMobile ? '90%' : '40%' }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Teacher Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Qualifications</TableCell>
              {authData?.role === '0' && <TableCell style={{ fontWeight: 'bold' }} >Action</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.teacherName}</TableCell>
                <TableCell>{row.qualifications}</TableCell>
                {authData?.role === '0' && (
                  <TableCell>
                    <IconButton onClick={() => handleDeleteRow(row)}>
                      <DeleteIcon color="error" />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {authData?.role === '0' && (
        <Button
          variant="contained"
          color="primary"
          onClick={()=>{handleAddRow()}}
          style={{ marginBottom: '20px' }}
        >
          Add Teacher &nbsp; {isLoading && <CircularProgress style={{ color: 'white' }} size={25} disableShrink />}
        </Button>
      )}

      <Dialog open={open || false} onClose={() => setOpen(false)} fullWidth>
        <DialogTitle>Teacher</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label='Name'
            type="text"
            fullWidth
            variant="standard"
            value={teacher.teacherName}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
              }
            }}
            onChange={(e) => setTeacher({ ...teacher, teacherName: e.target.value })}
          />
          <TextField
            margin="dense"
            id="qualifications"
            label='Qualifications'
            type="text"
            fullWidth
            variant="standard"
            value={teacher.qualifications}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                handleSave()
              }
            }}
            onChange={(e) => setTeacher({ ...teacher, qualifications: e.target.value })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleSave()}>Save</Button>
          <Button onClick={() => {setOpen(false); setTeacher({ teacherName: '', qualifications: '' })}}>Cancel</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={openDelete || false} onClose={() => setOpenDelete(false)} fullWidth>
        <DialogTitle>Are you sure you want to delete</DialogTitle>
        <DialogContent>
          <Typography>
            {teacherDelete.teacherName}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleDelete()}>Delete</Button>
          <Button onClick={() => {setOpenDelete(false); setTeacherDelete({})}}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Staff;
