import { Container, Typography } from '@mui/material'
import React from 'react'

const HomeBody = () => {
  return (
    <>
        <Container maxWidth="lg">
      <div className="d-flex flex-column flex-md-row justify-content-center align-items-center">
        <div className="text-md-left col-lg-6 col-md-6 col-sm-12" style={{margin:'10px'}}>
          <h1 style={{fontSize:'40px', fontWeight:'bold'}} >
            Welcome to St. Lawrence High School
          </h1>
          <p style={{textAlign:'justify'}}>
    St. LAWRENCE HIGH SCHOOL, recognized by the Government of Telangana up to S.S.C., stands as a minority institution. Established in 1987 under the guidance of Mrs. & Mr. G.S. IQBAL, the school's primary goal is to provide top-notch education, eradicate illiteracy, and foster excellence among students. The management team comprises highly qualified experts in education, dedicated to delivering excellent education with a minimal fee structure.
    <br />
    <br />
    The institution goes beyond academics, aiming to prepare students for life by nurturing qualities of the mind and heart. It focuses on developing skills and attitudes that empower students to navigate life's challenges, fostering their ability to become engaged and contributing members of society and the nation. St. LAWRENCE HIGH SCHOOL remains committed to its motto, ensuring that education not only enlightens young minds but also serves as a catalyst for holistic development, laying the foundation for a brighter future.
</p>

        </div>
        <div className="col-lg-6 col-md-6 col-sm-12">
        <img
          src={process.env.PUBLIC_URL + '/img/Dad.jpg'}
          alt="Image"
          className="img-fluid"
          style={{ width: '100%', margin: '10px' }}
          />
          </div>
      </div>
    </Container>
    </>
  )
}

export default HomeBody
