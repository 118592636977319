import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { signup } from '../actions/auth';
import { CircularProgress } from '@mui/material';

const Signup = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLogin, isLoading } = useSelector((state) => state.auth);
    if(!isLogin)
        navigate('/')

    const initialState = {
        email: "",
        password: "",
    };

    const [authData, setAuthData] = useState(initialState);
    const [confirmPassword, setConfirmPassword] = useState('')

    const handleChange = (e) => {
        setAuthData({ ...authData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if(authData.password===confirmPassword)
        {

            if (Object.values(authData).includes("")) alert("Please fill all the fields");
            else dispatch(signup(authData));
            setAuthData(initialState)
            setConfirmPassword('')
        }
        else
            alert('Passwords are not equal')
    };

    return (
        <>
            <br />
            <section>
                <div className="container">
                    <div className="sign-form px-auto">
                        <h2>Signup</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <input style={{ color: '#333' }} type="email" value={authData.email} name="email" id="email" placeholder="Email Id" onChange={handleChange} />
                                </div>
                                <div className="col-lg-12">
                                    <input style={{ color: '#333' }} type="password" value={authData.password} name="password" id="password" placeholder="Password" onChange={handleChange} />
                                </div>
                                <div className="col-lg-12">
                                    <input style={{ color: '#333' }} type="password" value={confirmPassword} name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" onChange={(e)=>setConfirmPassword(e.target.value)} />
                                </div>
                                <div className="offset-lg-4 col-lg-4 justify-content-center">
                                    <button type="submit" className="site-btn">Sign Up &nbsp; {isLoading && <CircularProgress style={{ position: 'absolute', color: 'white' }} size={25} disableShrink />}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <br />
        </>
    );
};

export default Signup
