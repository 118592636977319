import React from 'react';

const AimsObjectives = () => {
  const headerStyle = {
    fontSize: '37px', // Reduced font size
    fontWeight: 'bold', // Bold font
    color: 'navy', // Navy blue color
    paddingTop: '5%',
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h1 className="text-center pt-5" style={headerStyle}>
                AIMS AND OBJECTIVES
              </h1>
              <div className="aims-content">
                <p className="pt-3" style={{textAlign:'justify'}}>
                This institution is established by a group of expert academicians, young scientist, professional and philanthropists to educate students. No child is born with every thing in his/her mind. Also as a matter of fact no child is weak child. Every individual has some hidden knowledge. It is only the encouragement and influence of the elders like parents and teachers that the hidden knowledge shines. This is achieved greatly by teachers who educate the individual. An expert master know how to bring the best out of his/her country. And good citizens are prepared only by expert, sincere and dedicated teachers. Hence our institution shall exploit the hidden knowledge and strength sharpen and shine the lives of our students by giving intensive, qualitative and result oriented education.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AimsObjectives;
