import { CREATE_IMAGES, DELETE_IMAGES, END_LOADING_IMAGES, FETCH_ALL_IMAGES, START_LOADING_IMAGES } from "../constants/gallery";



export default (state = { isLoading: false, gallery: [{}], numberOfPages:'0' }, action) => {
    switch (action.type) {
        case START_LOADING_IMAGES:
            return {
                ...state,
                isLoading: true
            }
        case END_LOADING_IMAGES:
            return {
                ...state,
                isLoading: false
            }
        case DELETE_IMAGES:
            return {
                ...state,
                gallery: state.gallery.filter((image) => image._id !== action.payload._id)
            };
        case FETCH_ALL_IMAGES:
            // console.log(action.payload)
            return {
                ...state,
                gallery: action.payload.data,
                numberOfPages: action.payload.numberOfPages
            };
        case CREATE_IMAGES:
            return {
                ...state,
                gallery: [...state.gallery, ...action.payload]
            };
        default:
            return state;
    }
}
