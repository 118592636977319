import * as api from '../api'
import { AUTH, AUTH_DATA, END_LOADING_AUTH, START_LOADING_AUTH } from '../constants/auth';
import decode from 'jwt-decode'



export const login = (authData, navigate) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_AUTH })
        const { data } = await api.login(authData);
        const decodedData = decode(data.token)
        dispatch({ type: AUTH, payload:{data, decodedData} })

        dispatch({ type: END_LOADING_AUTH })

        navigate('/')
    } catch (error) {
        console.log(error)
        alert(error?.response?.data?.message)
        dispatch({ type: END_LOADING_AUTH })
    }
}

export const signup = (authData) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING_AUTH })
        await api.signup(authData);
        dispatch({ type: END_LOADING_AUTH })
        alert(`${authData.email} created`)
    } catch (error) {
        console.log(error)
        alert(error?.response?.data?.message)
        dispatch({ type: END_LOADING_AUTH })
    }
}

export const deleteAccount = (email) => async(dispatch) => {
    try {
        dispatch({ type: START_LOADING_AUTH })
        await api.deleteAccount(email)
        dispatch({ type: END_LOADING_AUTH })
        alert(`${email} deleted`)
    } catch (error) {
        console.log(error)
        alert(error?.response?.data?.message)
        dispatch({ type: END_LOADING_AUTH })
    }
}

export const fillAuthData = (decodedToken) => async(dispatch) =>{
    try {
        dispatch({ type: AUTH_DATA, payload: decodedToken })
    } catch (error) {
        console.log(error)
    }
}