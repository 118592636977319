import React, { useEffect, useState } from 'react'
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Snackbar, TextField, TextareaAutosize } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux'
import { createHomework, deleteHomework, deleteHomeworkImage, getHomeworks, updateHomework } from '../actions/homework';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router-dom';
import './Homework.css'



const Homework = () => {



  const params = useParams()
  const { isLogin } = useSelector((state) => state.auth)
  const className = params.class

  const { homeworks } = useSelector((state) => state.homework)
  const dispatch = useDispatch()
  // const [open, setOpen] = useState(false);
  const [open, setOpen] = useState({});
  // const [selectedFile, setSelectedFile] = useState({})
  const [imageBase64, setImageBase64] = useState({});
  const [imageURL, setImageURL] = useState({});



  useEffect(() => {
    dispatch(getHomeworks(className))

  }, [className])








  const [components, setComponents] = useState(homeworks);

  useEffect(() => {
    setComponents(homeworks)
  }, [homeworks])

  const [edit, setEdit] = useState()

  const [openSnackbar, setOpenSnackbar] = useState({});
  const handleSnackbarClose = (index) => {
    setOpenSnackbar({ ...openSnackbar, [index]: false });
  };
  const [openSnackbar0, setOpenSnackbar0] = useState({});
  const handleSnackbarClose0 = (index) => {
    setOpenSnackbar0({ ...openSnackbar0, [index]: false });
  };
  const [openSnackbar1, setOpenSnackbar1] = useState({});
  const handleSnackbarClose1 = (index) => {
    setOpenSnackbar1({ ...openSnackbar1, [index]: false });
  };

  const addComponent = () => {
    dispatch(createHomework({ title: 'Title', bodyText: 'The body can be text or image or both', note: '', className, bodyImages: [] }))
    // setComponents([...components, { title: 'Sample Title 1', body: 'The body can be text or image or both', note: 'Sample Note' }]);
  };
  const deleteHomeworkButton = (_id) => {
    // console.log(components.filter((_,i)=>(i!==index)))
    // setComponents(components.filter((_, i) => (i !== index)));
    dispatch(deleteHomework(_id))
  };

  const onDelete = (property, index) => {
    setComponents((prevComponents) => {
      const updatedComponents = [...prevComponents];
      updatedComponents[index][property] = '';
      return updatedComponents;
    });
  };

  const onSave = (id, homework, index) => {
    // dispatch(createHomework(homework))
    // alert('Body image edit in homework controller')
    // Title cannot be empty
    // classroom
    handleSnackbarClose1(index)
    let clonedHomework = JSON.parse(JSON.stringify(homework))
    // console.log(components[index])
    if (imageBase64[index]) {
      clonedHomework.bodyImages.push(...imageBase64[index])
      imageBase64[index] = []
    }
    // console.log(clonedHomework)
    // console.log(components[index])
    dispatch(updateHomework(id, clonedHomework, setOpenSnackbar, setOpenSnackbar0, index)).then(() => {
      // After successfully updating, fetch the updated homeworks
      dispatch(getHomeworks(className));
      setImageURL((prevImageURL) => ({
        ...prevImageURL,
        [index]: [],
      }));
      setImageBase64((prevImageBase64) => ({
        ...prevImageBase64,
        [index]: [],
      }));
    });
  }


  // const handleOpen = (index) => {
  //   setOpen(open=>open.map((op,i)=> i===index?true:op));
  // };

  const handleOpen = (index, field) => {
    setOpen({ ...open, [index]: { ...open[index], [field]: true } });
  };

  const handleClose = (index, field) => {
    setOpen({ ...open, [index]: { ...open[index], [field]: false } });
  };
  const addURL = (index, URL) => {
    setImageURL((prevImageURL) => ({
      ...prevImageURL,
      [index]: [...(prevImageURL[index] || []), ...URL],
    }));
  };

  const addBase64 = (index, base64) => {
    setImageBase64((prevImageBase64) => ({
      ...prevImageBase64,
      [index]: [...(prevImageBase64[index] || []), ...base64],
    }));
  };

  const handleSave = (index, field, value) => {
    const updatedComponents = [...components];
    updatedComponents[index] = {
      ...updatedComponents[index],
      [field]: value,
    };
    setComponents(updatedComponents);
    handleClose(index, field)
    setOpenSnackbar1((openSnackbar) => ({ ...openSnackbar, [index]: true }))
  };




  const toDataURL = (url, callback) => {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
      var reader = new FileReader();
      reader.onloadend = function () {
        callback(reader.result);
      };
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  const handleFileSelect = (event, index) => {
    const files = event.target.files;

    // Check if any files were selected
    if (files.length === 0) {
      return;
    }

    const imageURLs = [];
    const base64DataArray = [];

    // Process each selected file
    for (const file of files) {
      const imageURL = URL.createObjectURL(file);
      imageURLs.push(imageURL);

      // Use the toDataURL function to convert the image URL to base64
      toDataURL(imageURL, function (base64Data) {
        // Add the base64 data to the array
        base64DataArray.push(base64Data);

        // If all files have been processed, you can perform additional actions here
        if (base64DataArray.length === files.length) {
          // Now you have an array of imageURLs and an array of base64 data
          // You can add them to your state or perform other operations
          addURL(index, imageURLs);
          addBase64(index, base64DataArray);
        }
      });
    }
    setOpenSnackbar1((openSnackbar) => ({ ...openSnackbar, [index]: true }))
    // console.log(imageURLs)
  };



  const handleImageDelete = (index, i, type) => {
    //There are images of 2 types
    if (type === 'a')
      dispatch(deleteHomeworkImage(components[index]._id, components[index].bodyImages[i].public_id))
    else {
      setImageBase64((prevImageBase64) => {
        const newImageBase64 = { ...prevImageBase64 };
        if (newImageBase64[index]) {
          newImageBase64[index].splice(i, 1);
        }
        return newImageBase64;
      });

      setImageURL((prevImageURL) => {
        const newImageURL = { ...prevImageURL };
        if (newImageURL[index]) {
          newImageURL[index].splice(i, 1);
        }
        return newImageURL;
      });

    }
  }
  const formatDateString = (dateString) => {
    if (typeof dateString === 'string' && dateString.length >= 10) {
      const date = new Date(dateString);
      const options = { day: 'numeric', month: 'long', year: 'numeric' };
      return date.toLocaleDateString('en-GB', options);
    }
    return ''; // Invalid input, return null or handle the error as needed
  };

  return (
    homeworks.length > 0 ? <div className="container text-center">
      {
        components.map(({ title, bodyText, note, _id, bodyImages, className, createdAt }, index) => (
          className === params.class && <div className="component" key={index}>
            <div className="component-header">
              <p style={{ marginBottom: '0px', fontWeight: 'bold' }}>{formatDateString(createdAt)}</p>
              <h3 className="font-weight-bold">{title}</h3>
              <Dialog open={open[index]?.title || false} onClose={() => handleClose(index, 'title')} fullWidth>
                <DialogTitle>New Title</DialogTitle>
                <DialogContent>
                  {/* <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label='Title'
                    type="text"
                    fullWidth
                    variant="standard"
                    value={edit}
                    onChange={(e) => setEdit(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setEdit(prevEdit => prevEdit + '\n');
                      }}}
                  /> */}
                  <TextareaAutosize
                    autoFocus
                    minRows={1}
                    value={edit}
                    style={{ width: '100%', border: 'none', borderBottom: '1px solid black', resize: 'none' }}
                    onChange={(e) => setEdit(e.target.value)}
                  />

                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleSave(index, 'title', edit)}>Save</Button>
                  <Button onClick={() => handleClose(index, 'title')}>Cancel</Button>
                </DialogActions>
              </Dialog>
              {isLogin && <>
                <Button size='small' style={{ margin: '10px' }} variant='contained' color={'primary'} onClick={() => { setEdit(title); handleOpen(index, 'title') }}>
                  Edit Title
                </Button>
                <Button size='small' style={{ margin: '10px' }} variant='contained' color={'warning'} onClick={() => onDelete('title', index)}>
                  Delete
                </Button>
              </>
              }
            </div>
            <div className="component-body">
              <Dialog open={open[index]?.bodyText || false} onClose={() => handleClose(index, 'bodyText')} fullWidth>
                <DialogTitle>New Body</DialogTitle>
                <DialogContent>
                  {/* <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label='Body'
                    type="text"
                    fullWidth
                    variant="standard"
                    value={edit}
                    onChange={(e) => setEdit(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setEdit(prevEdit => prevEdit + '\n');
                      }}}
                  /> */}
                  <TextareaAutosize
                    autoFocus
                    minRows={1}
                    value={edit}
                    onChange={(e) => setEdit(e.target.value)}
                    style={{ width: '100%', border: 'none', borderBottom: '1px solid black', resize: 'none' }}

                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setEdit(prevEdit => prevEdit + '\n');
                      }
                    }}
                  />

                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleSave(index, 'bodyText', edit)}>Save</Button>
                  <Button onClick={() => handleClose(index, 'bodyText')}>Cancel</Button>
                </DialogActions>
              </Dialog>
              <div className='flexDiv' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap' }}>
                {
                  bodyImages?.map((image, i) => (
                    <div key={i}  >
                      <img style={{ maxWidth: '100%', maxHeight: '200px', margin: '10px' }} src={image.url} alt={''} />
                      {isLogin && <IconButton color="error" sx={{ borderRadius: 0 }} onClick={() => handleImageDelete(index, i, 'a')}> <DeleteIcon /> </IconButton>}
                    </div>
                  ))
                }
                {imageURL[index]?.map((url, i) => (
                  <div key={url}  >
                    <img style={{ maxWidth: '100%', maxHeight: '200px', margin: '10px' }} src={url} alt={''} />
                    {isLogin && <IconButton color="error" sx={{ borderRadius: 0 }} onClick={() => handleImageDelete(index, i, 'b')}> <DeleteIcon /> </IconButton>}
                  </div>
                ))
                }
              </div>
              <p className="h5">
                {bodyText?.split('\n').map((text, index) => (
                  <React.Fragment key={index}>
                    {text}
                    <br />
                  </React.Fragment>
                ))}
              </p>

              {isLogin && <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'flex-start' }}>
                <div>
                  <input
                    type="file"
                    accept="image/*" // Accept only image files
                    style={{ display: 'none' }}
                    id={`image-upload-${index}`}
                    onChange={(e) => { handleFileSelect(e, index) }}
                    multiple
                  />
                  <label htmlFor={`image-upload-${index}`}>
                    <Button
                      size="small" // Set the size to 'small'
                      style={{ margin: '10px' }}
                      variant="contained"
                      component="span"
                      startIcon={<CloudUploadIcon />} // Icon for the button
                    >
                      Upload Image
                    </Button>
                  </label>
                </div>
                <Button size='small' style={{ margin: '10px' }} variant='contained' color={'primary'} onClick={() => { setEdit(bodyText); handleOpen(index, 'bodyText') }}>
                  Edit Text
                </Button>
                <Button size='small' style={{ margin: '10px' }} variant='contained' color={'warning'} onClick={() => onDelete('body', index)}>
                  Delete
                </Button>
              </div>}
            </div>
            <div className="component-footer">
              <p style={{ color: 'red' }}>{note}</p>
              <Dialog open={open[index]?.note || false} onClose={() => handleClose(index, 'note')} fullWidth>
                <DialogTitle>New Note</DialogTitle>
                <DialogContent>
                  {/* <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label='Note'
                    type="text"
                    fullWidth
                    variant="standard"
                    value={edit}
                    onChange={(e) => setEdit(e.target.value)}
                  /> */}
                  <TextareaAutosize
                    autoFocus
                    minRows={1}
                    value={edit}
                    onChange={(e) => setEdit(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        e.preventDefault();
                        setEdit(prevEdit => prevEdit + '\n');
                      }
                    }}
                    style={{ width: '100%', border: 'none', borderBottom: '1px solid black', resize: 'none' }}
                  />

                </DialogContent>
                <DialogActions>
                  <Button onClick={() => handleSave(index, 'note', edit)}>Save</Button>
                  <Button onClick={() => handleClose(index, 'note')}>Cancel</Button>
                </DialogActions>
              </Dialog>
              {isLogin && <>
                <Button size='small' style={{ margin: '10px' }} variant='contained' color={'primary'} onClick={() => { setEdit(note); handleOpen(index, 'note') }}>
                  Put a Note
                </Button>
                <Button size='small' style={{ margin: '10px' }} variant='contained' color={'warning'} onClick={() => onDelete('note', index)}>
                  Delete
                </Button>
              </>
              }
            </div>
            {isLogin && <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: "center" }}>
              <Button size='small' style={{ margin: '10px' }} variant='contained' color='success' onClick={() => onSave(_id, components[index], index)}>
                Save
              </Button>
              <Snackbar open={openSnackbar[index] || false} autoHideDuration={5000} onClose={() => handleSnackbarClose(index)}>
                <Alert onClose={() => handleSnackbarClose(index)} severity="success" sx={{ width: '100%' }}>
                  {title} has been saved
                </Alert>
              </Snackbar>
              <Snackbar open={openSnackbar0[index] || false} autoHideDuration={5000} onClose={() => handleSnackbarClose0(index)}>
                <Alert onClose={() => handleSnackbarClose0(index)} severity="info" sx={{ width: '100%' }}>
                  {title} is being saved
                </Alert>
              </Snackbar>
              <Snackbar open={openSnackbar1[index] || false} autoHideDuration={null} onClose={() => handleSnackbarClose1(index)}>
                <Alert onClose={() => handleSnackbarClose1(index)} severity="info" sx={{ width: '100%' }}>
                  {title} is being edited
                </Alert>
              </Snackbar>
              <Button size='small' style={{ margin: '10px' }} variant='contained' color={'warning'} onClick={() => deleteHomeworkButton(_id)}>
                Delete Homework
              </Button>
            </div>}
          </div>
        ))}
      {isLogin && <Button size='small' style={{ margin: '10px' }} variant='contained' color={'secondary'} onClick={() => addComponent()}>
        Add Homework
      </Button>}
    </div> : <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '80vh', // 80% of the viewport height
        }}
      >
        <div
          style={{
            textAlign: 'center',
            fontFamily: 'Arial, sans-serif',
          }}
        >
          <div
            style={{
              fontSize: '24px',
              fontWeight: 'bold',
              marginBottom: '20px',
            }}
          >
            No Homework
          </div>
          {isLogin && <Button size='small' style={{ margin: '10px' }} variant='contained' color={'secondary'} onClick={() => addComponent()}>
        Add Homework
      </Button>}
        </div>
      </div>
    </>
  );
}

export default Homework
