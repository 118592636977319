import React from 'react'

const schoolUniform = () => {
    const headerStyle = {
        fontSize: '37px', // Reduced font size
        fontWeight: 'bold', // Bold font
        color: 'navy', // Navy blue color
        paddingTop: '5%',
      };
    
      return (
        <>
          <section>
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <h1 className="text-center pt-3" style={headerStyle}>
                    SCHOOL UNIFORM
                  </h1>
                  <div className="aims-content">
                    <p className="pt-3" style={{textAlign:'center', marginBottom:'100px'}}>
                    BOYS: Maroon Checks Shirt, Brown Color Pant, Black Shoes, Black Socks, School Belt, Tie and Badge.
                      <br/>
                    GIRLS Maroon Checks Shirt, Biscuit Color Pinafore, Black Shoes, Black Socks, School Belt, Tie and Badge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      );
}

export default schoolUniform
