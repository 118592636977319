import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteAccount } from '../actions/auth';
import { CircularProgress } from '@mui/material';

const Delete = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLogin, isLoading } = useSelector((state) => state.auth);
    if(!isLogin)
        navigate('/')

    const initialState = {
        email: "",
    };

    const [authData, setAuthData] = useState(initialState);

    const handleChange = (e) => {
        setAuthData({ ...authData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
            if (Object.values(authData).includes("")) alert("Please fill all the fields");
            else dispatch(deleteAccount(authData.email));
            setAuthData(initialState)
    };

    return (
        <>
            <br />
            <section>
                <div className="container">
                    <div className="sign-form px-auto">
                        <h2>Enter Email to be deleted</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <input style={{ color: '#333' }} type="email" value={authData.email} name="email" id="email" placeholder="Email Id" onChange={handleChange} />
                                </div>
                                <div className="offset-lg-4 col-lg-4 justify-content-center">
                                    <button type="submit" className="site-btn">Delete &nbsp; {isLoading && <CircularProgress style={{ position: 'absolute', color: 'white' }} size={25} disableShrink />}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <br />
        </>
    );
};

export default Delete
