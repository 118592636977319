import { ADD_FEES, DELETE_FEES, END_LOADING_FEES, GET_FEES, START_LOADING_FEES, UPDATE_FEES } from "../constants/fees";



export default (state = { isLoading: false, fees: [{}] }, action) => {
    switch (action.type) {
        case START_LOADING_FEES:
            return {
                ...state,
                isLoading: true
            }
        case END_LOADING_FEES:
            return {
                ...state,
                isLoading: false
            }
        case DELETE_FEES:
            return {
                ...state,
                fees: state.fees.filter((fees) => fees._id !== action.payload)
            };
        case GET_FEES:
            // console.log(action.payload)
            return {
                ...state,
                fees: action.payload.data
            };
        case ADD_FEES:
            return {
                ...state,
                fees: [...state.fees, action.payload]
            };
        case UPDATE_FEES:
            return {
                ...state,
                fees: state.fees.map((fee)=>fee._id == action.payload._id ? action.payload : fee)
            };
        default:
            return state;
    }
}
