import { Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Pagination, PaginationItem, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from 'react-redux';
import { createImages, deleteImage, getImages } from '../actions/gallery';
import { Link, useLocation } from 'react-router-dom';


function useQuery() {
    return new URLSearchParams(useLocation().search)
}

const Gallery = () => {
    const [open, setOpen] = useState({});
    const [openSelected, setOpenSelected] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [checked, setChecked] = useState([])
    const [dialogImage, setDialogImage] = useState()
    const [galleryImages, setGalleryImages] = useState([{}])
    const { gallery, numberOfPages } = useSelector((state) => state.gallery)
    const { isLogin } = useSelector((state) => state.auth)
    const [imageBase64, setImageBase64] = useState([]);
    const [imageURL, setImageURL] = useState([]);
    const dispatch = useDispatch()
    const query = useQuery()
    const page = query.get('page') || '1'
    useEffect(() => {
        dispatch(getImages(page))
    }, [page])


    useEffect(() => {
        setGalleryImages(gallery)
    }, [gallery])
    const handleCheckChange = (i) => {
        setChecked((prevChecked) => prevChecked.includes(i) ? prevChecked.filter((val) => val !== i) : [...prevChecked, i])
    }

    const deleteSelectedImages = () => {
        // console.log(checked)
        const imagesToBeDeleted = checked.map((val) => galleryImages[val])
        dispatch(deleteImage(imagesToBeDeleted))
        setChecked([])
        setOpenSelected(false)
    }
    const deleteSelectedImage = (i) => {
        dispatch(deleteImage([galleryImages[i]]))
        closeOpen(i)
    }

    const toDataURL = (url, callback) => {
        var xhr = new XMLHttpRequest();
        xhr.onload = function () {
            var reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    }

    const handleFileSelect = (event) => {
        const files = event.target.files;

        if (files && files.length > 0) {
            // Create arrays to store the URLs and base64 data
            const imageURLs = [];
            const base64DataArray = [];

            // Process each selected file
            for (let i = 0; i < files.length; i++) {
                const file = files[i];

                // Create an URL for the selected image
                const imageURL = URL.createObjectURL(file);
                imageURLs.push(imageURL);

                // Use the toDataURL function to convert the image URL to base64
                toDataURL(imageURL, (base64Data) => {
                    base64DataArray.push(base64Data);

                    // If we've processed all files, add the URLs and base64 data to state
                    //   if (base64DataArray.length === files.length) {
                    //   }
                });
            }
            setImageURL(imageURLs);
            setImageBase64(base64DataArray);
            setOpenDialog(true)
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImageUrl, setSelectedImageUrl] = useState('');

    const openModal = (imageUrl) => {
        setSelectedImageUrl(imageUrl);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openOpen = (index) => {
        setDialogImage(galleryImages[index].url)
        setOpen({ ...open, [index]: true });
    }
    const closeOpen = (index) => {
        setDialogImage()
        setOpen({ ...open, [index]: false });
    }

    const addImages = () => {
        dispatch(createImages(imageBase64))
        setOpenDialog(false)
    }
    const deleteImageIndex = (index) => {
        setImageURL((imageURL) => imageURL.filter((_, i) => i !== index))
        setImageBase64((imageBase64) => imageBase64.filter((_, i) => i !== index))
    }
    const formatDateString = (dateString) => {
        if (typeof dateString === 'string' && dateString.length >= 10) {
            const date = new Date(dateString);
            const options = { day: 'numeric', month: 'long', year: 'numeric' };
            return date.toLocaleDateString('en-GB', options);
        }
        return ''; // Invalid input, return null or handle the error as needed
    };



    return (
        <>
            <div style={{ margin: '5vh 10vw', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <h5 style={{ marginBottom: '2%' }}>Click the image to expand</h5>
                {isLogin && <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'flex-start', justifyContent: 'space-between' }}>

                    <div>
                        <input
                            type="file"
                            accept="image/*" // Accept only image files
                            style={{ display: 'none' }}
                            id={`image-upload`}
                            multiple
                            onChange={(e) => { handleFileSelect(e) }}
                        />
                        <label htmlFor={`image-upload`}>
                            <Button
                                size="small" // Set the size to 'small'
                                style={{ margin: '10px' }}
                                variant="text"
                                component="span"
                            >
                                Add Images
                            </Button>
                        </label>
                    </div>
                    <div>
                        <Button
                            size="small" // Set the size to 'small'
                            style={{ margin: '10px' }}
                            variant="text"
                            component="span"
                            color='error'
                            onClick={() => setOpenSelected(true)}
                            disabled={checked.length === 0}
                        >
                            Delete Selected Images
                        </Button>
                    </div>
                </div>}
                <div
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '20px', // Adjust the spacing between images
                    }}
                >
                    {galleryImages?.map((image, i) => (
                        <div
                            key={image._id}
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '300px', // Set the fixed width
                                height: '200px', // Set the fixed height
                                margin: '10px'
                            }}
                        >
                            <img
                                style={{
                                    maxWidth: '300px', // Set the fixed width
                                    minHeight: '200px', // Set the fixed height
                                    // margin: '10px',
                                    cursor: 'pointer', // Change cursor to pointer on hover
                                }}
                                src={image.url}
                                alt={''}
                                onClick={() => openModal(image.url)}
                            />

                            {isModalOpen && (
                                <div
                                    style={{
                                        position: 'fixed',
                                        top: '0',
                                        left: '0',
                                        right: '0',
                                        bottom: '0',
                                        backgroundColor: 'rgba(0, 0, 0, 0.15)',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        zIndex: '9999',
                                    }}
                                    onClick={closeModal}
                                >
                                    <img
                                        src={selectedImageUrl}
                                        alt=""
                                        style={{ maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }}
                                    />
                                </div>
                            )}

                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center' }}>
                                <span>
                                    <Typography variant="body2" align="center">
                                        {formatDateString(image.createdAt)}
                                    </Typography>
                                </span>
                                {isLogin && <span style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                                    <Checkbox
                                        size='small'
                                        checked={checked.includes(i)}
                                        onChange={() => handleCheckChange(i)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <IconButton
                                        color="error"
                                        sx={{ borderRadius: 0 }}
                                        onClick={() => openOpen(i)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </span>}
                            </div>
                            <Dialog open={open[i] || false} onClose={() => closeOpen(i)} >
                                <DialogTitle>Are you sure you want to delete this image</DialogTitle>
                                <DialogContent>
                                    <img style={{ height: '200px', width: '300px' }} src={dialogImage} alt='' />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => deleteSelectedImage(i)}>Delete</Button>
                                    <Button onClick={() => closeOpen(i)}>Cancel</Button>
                                </DialogActions>
                            </Dialog>

                        </div>
                    )
                    )}


                </div>
                <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth >
                    <DialogTitle>These are the images selected</DialogTitle>
                    <DialogContent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >
                            {imageURL.map((image, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '5px', // Add margin to create spacing
                                        width: 'calc(50% - 10px)', // Set width for 2 images per row
                                        // You can adj  ust the width percentage and margin as needed
                                    }}
                                >
                                    <img
                                        style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                                        src={image}
                                        alt=""
                                    />
                                    <IconButton
                                        color="error"
                                        onClick={() => deleteImageIndex(index)}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </div>
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => addImages()}>Save Images</Button>
                        <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openSelected || false} onClose={() => setOpenSelected(false)} fullWidth >
                    <DialogTitle>Are you sure you want to delete these images</DialogTitle>
                    <DialogContent>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexWrap: 'wrap',
                            }}
                        >

                            {checked.map((val, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        margin: '5px', // Add margin to create spacing
                                        width: 'calc(50% - 10px)', // Set width for 2 images per row
                                        // You can adj  ust the width percentage and margin as needed
                                    }}
                                >

                                    <img
                                        style={{ height: '200px', width: '100%', objectFit: 'cover' }}
                                        src={galleryImages[val].url} alt='' />
                                </div>
                            ))}
                        </div>

                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => deleteSelectedImages()}>Delete</Button>
                        <Button onClick={() => setOpenSelected(false)}>Cancel</Button>
                    </DialogActions>
                </Dialog>
                <div style={{ marginTop: '40px' }}>
                    <Pagination
                        defaultPage={Number(page) || 1}
                        count={numberOfPages}
                        color="primary"
                        renderItem={(item) => (
                            <PaginationItem
                                {...item}
                                component={Link}
                                to={`/gallery?page=${item.page}`}
                            />
                        )}
                    />
                </div>
            </div>

        </>
    );
};

export default Gallery;
