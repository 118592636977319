import * as api from '../api'
import { ADD_STAFF, DELETE_STAFF, END_LOADING_STAFF, GET_STAFF, START_LOADING_STAFF } from '../constants/staff';


export const getStaff = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING_STAFF})
        const {data} = await api.getStaff();
        // console.log(data.data)
        dispatch({type:GET_STAFF,payload:data});
        dispatch({type:END_LOADING_STAFF})
    }
    catch(error)
    {
        console.log(error);
    }
}

export const addStaff = (staffMember) => async(dispatch) => {
    try {
        // console.log(images)
        dispatch({type:START_LOADING_STAFF})        
        const {data} = await api.addStaff(staffMember);
        dispatch({type:ADD_STAFF,payload:data.data});
        dispatch({type:END_LOADING_STAFF})            
    } catch (error) {
        console.log(error)
    }
} 

export const deleteStaff = (_id) => async(dispatch) => {
    try {
            await api.deleteStaff(_id)
            dispatch({type:DELETE_STAFF,payload:_id})
    } catch (error) {
        console.log(error)
    }
}