import Footer from './Components/Footer';
import Header from './Components/Header';
import Home from './Components/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Subjects from './Components/Subjects';
import VisionMission from './Components/VMBody';
import AimsObjectives from './Components/AimsObjectives';
import Curriculum from './Components/Curriculum';
import Homework from './Components/Homework';
import Gallery from './Components/Gallery';
import Login from './Components/Login';
import Signup from './Components/Signup';
import Delete from './Components/Delete';
import SchoolUniform from './Components/SchoolUniform';
import AdmissionProcess from './Components/AdmissionProcess';
import CodeOfConduct from './Components/CodeOfConduct';
import ContactUs from './Components/ContactUs';
import Staff from './Components/Staff';
import Fees from './Components/Fees';
import SchoolTimings from './Components/SchoolTimings';

function App() {
  
  return (
    <BrowserRouter>
      <Header />
      <Routes>
      <Route path='/'>
          <Route index element={<Home/>}/>
          <Route path='subjects'>
            <Route index element={<Subjects/>}/>
          </Route>
          <Route path='admission-process'>
            <Route index element={<AdmissionProcess/>}/>
          </Route>
          <Route path='school-uniform'>
            <Route index element={<SchoolUniform/>}/>
          </Route>
          <Route path='vision-mission'>
            <Route index element={<VisionMission/>}/>
          </Route>
          <Route path='aims-objectives'>
            <Route index element={<AimsObjectives/>}/>
          </Route>
          <Route path='curriculum'>
            <Route index element={<Curriculum/>}/>
          </Route>
          <Route path='contact-us'>
            <Route index element={<ContactUs/>}/>
          </Route>
          <Route path='code-of-conduct'>
            <Route index element={<CodeOfConduct/>}/>
          </Route>
          <Route path='homework'>
            <Route path=':class' element={<Homework/>}/>
          </Route>
          <Route path='gallery'>
            <Route index element={<Gallery/>}/>
          </Route>
          <Route path='school-timings'>
            <Route index element={<SchoolTimings/>}/>
          </Route>
          <Route path='login'>
            <Route index element={<Login/>}/>
          </Route>
          <Route path='staff'>
            <Route index element={<Staff/>}/>
          </Route>
          <Route path='fees'>
            <Route index element={<Fees/>}/>
          </Route>
          <Route path='signup'>
            <Route index element={<Signup/>}/>
          </Route>
          <Route path='delete-account'>
            <Route index element={<Delete/>}/>
          </Route>
      </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
