import * as api from '../api'
import { CREATE, DELETE, DELETE_IMAGE, END_LOADING, FETCH_ALL, START_LOADING, UPDATE } from '../constants/homework';

export const getHomeworks = (className) => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.getHomeworks(className);
        dispatch({type:FETCH_ALL,payload:data});
        dispatch({type:END_LOADING})
    }
    catch(err)
    {
        console.log(err);
    }
}

export const createHomework = (homework) => async(dispatch) => {
    try{
        dispatch({type:START_LOADING})
        const {data} = await api.createHomework(homework);
        console.log(data)
        dispatch({type:CREATE,payload:data});
        dispatch({type:END_LOADING})
        // We store the data in payload
    }
    catch(err)
    {
        console.log(err);
    }
}


export const updateHomework = (id, homework, setOpenSnackbar, setOpenSnackbar0, index) => async(dispatch) => {
    try{
        setOpenSnackbar0((openSnackbar)=>({...openSnackbar, [index]:true}))
        const {data} = await api.updateHomework(id, homework);
        console.log(data)
        dispatch({type:UPDATE,payload:data});
        setOpenSnackbar0((openSnackbar)=>({...openSnackbar, [index]:false}))
        setOpenSnackbar((openSnackbar)=>({...openSnackbar, [index]:true}));
    }
    catch(err)
    {
        console.log(err);
    }
}

export const deleteHomeworkImage = (_id, public_id) => async(dispatch) => {
    try {
        // const encodedPublicId = encodeURIComponent(public_id); 
        await api.deleteHomeworkImage(_id, public_id)
        dispatch({type:DELETE_IMAGE, payload:{_id, public_id}})
    } catch (error) {
        console.log(error)
    }
}

export const deleteHomework = (id) => async(dispatch) => {
    try {
        await api.deleteHomework(id)
        dispatch({type:DELETE, payload: id})
    } catch (error) {
        console.log(error)
    }
}