import React from 'react';

const CodeOfConduct = () => {
  const mainHeadingStyle = {
    fontSize: '37px', // Reduced font size
    fontWeight: 'bold', // Bold font
    color: 'navy', // Navy blue color
    paddingTop: '5%',
  };

  const headingStyle = {
    fontSize: '24px', // Reduced font size
    fontWeight: 'bold', // Bold font
  };

  const paragraphStyle = {
    fontSize: '16px', // Increased font size
    textAlign: 'justify', // Added text alignment
  };

  return (
    <>
      <section>
        <div className="container">
          <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">

            <h1 className="text-center" style={mainHeadingStyle}>
              CODE OF CONDUCT
            </h1>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="conduct-content pt-5">
                <h3 style={headingStyle}>Admission to St. Lawrence High School implies on the part of the student and the parents’ willingness to comply with the requirements, rules, and regulations of the school. They are as follows:</h3>
                <h3 style={headingStyle}>I. The School Uniform:</h3>
                <ul>
                  <li>All the students must come to school neat and tidy.</li>
                  <li>
                    The students, especially girls, are not allowed to wear or
                    bring ornaments (hanging earrings and rings) to school and are
                    not allowed to use makeup, lipstick, mehendi, nail polish, eye
                    liner, etc.
                  </li>
                  <li>
                    On regular working days and for school functions, the students
                    must wear the prescribed school uniform. In exceptional
                    circumstances, if a student is unable to wear the complete
                    school uniform, they would be allowed to attend the school
                    provided a request is made by the parent through this handbook.
                  </li>
                  <li>
                    All the photographs that are to be used for various official
                    purposes or to be attested by the school should be taken in the
                    school uniform. Only recent photographs will be accepted.
                  </li>
                </ul>

                <h3 style={headingStyle}>II. The School Property and Belongings:</h3>
                <ul>
                  <li>
                    Care must be taken of the school property. The students must not
                    scratch or spoil the furniture, write on the walls or in any way
                    damage what is provided for their use. Collective/individual
                    fines may be imposed to recover damages.
                  </li>
                  <li>
                    Students are responsible for the safe custody of their books and
                    belongings. It is not advisable for them to bring/exchange
                    valuables or money.
                  </li>
                  <li>
                    Video games, cameras, lasers, perfume bottles, iPods, tablets,
                    mobile phones, pen drives, gold chains, etc. are not allowed in
                    the school. If brought, they will be confiscated.
                  </li>
                  <li>Chewing gum is strictly prohibited in the school premises.</li>
                  <li>
                    No arrangement will be made to deliver the tiffin boxes or any
                    other articles to the students in the classroom during the school
                    working hours.
                  </li>
                  <li>
                    Students are not allowed to write or draw or disfigure each
                    other's school uniform.
                  </li>
                </ul>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default CodeOfConduct;
