import * as api from '../api'
import { CREATE_IMAGES, DELETE_IMAGES, END_LOADING_IMAGES, FETCH_ALL_IMAGES, START_LOADING_IMAGES } from '../constants/gallery';


export const getImages = (page) => async(dispatch) => {
    try{
        dispatch({type:START_LOADING_IMAGES})
        const {data} = await api.getImages(page);
        // console.log(data.data)
        dispatch({type:FETCH_ALL_IMAGES,payload:data});
        dispatch({type:END_LOADING_IMAGES})
    }
    catch(error)
    {
        console.log(error);
    }
}

export const createImages = (images) => async(dispatch) => {
    try {
        // console.log(images)
        const {data} = await api.createImages(images);
        dispatch({type:CREATE_IMAGES,payload:data.data});
    } catch (error) {
        console.log(error)
    }
} 

export const deleteImage = (images) => async(dispatch) => {
    try {
        for (const image of images)
        {
            await api.deleteImage(image._id)
            dispatch({type:DELETE_IMAGES,payload:image})
        }
    } catch (error) {
        console.log(error)
    }
}