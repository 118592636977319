import { CREATE_CAROUSAL_IMAGE, DELETE_CAROUSAL_IMAGE, END_LOADING_CAROUSAL_IMAGE, FETCH_ALL_CAROUSAL_IMAGES, START_LOADING_CAROUSAL_IMAGE } from "../constants/carousal";



export default (state = { isLoading: false, carousalImages: [{}] }, action) => {
    switch (action.type) {
        case START_LOADING_CAROUSAL_IMAGE:
            return {
                ...state,
                isLoading: true
            }
        case END_LOADING_CAROUSAL_IMAGE:
            return {
                ...state,
                isLoading: false
            }
        case DELETE_CAROUSAL_IMAGE:
            return {
                ...state,
                carousalImages: state.carousalImages.filter((image) => image._id !== action.payload._id)
            };
        case FETCH_ALL_CAROUSAL_IMAGES:
            // console.log(action.payload)
            return {
                ...state,
                carousalImages: action.payload.data,
            };
        case CREATE_CAROUSAL_IMAGE:
            return {
                ...state,
                carousalImages: [...state.carousalImages, action.payload]
            };
        default:
            return state;
    }
}
