import React from 'react';

const Curriculum = () => {
    const headerStyle = {
        fontSize: '37px', // Reduced font size
        fontWeight: 'bold', // Bold font
        color: 'navy', // Navy blue color
        paddingTop: '2%',
    };

    return (
        <>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 mb-5">
                            <h1 className="text-center pt-3" style={headerStyle}>
                                Curriculum
                            </h1>
                            <div className="aims-content">
                                <p className="pt-4" style={{textAlign:'justify'}}>
                                The medium of instruction being English yet other languages like Hindi, Urdu and Telugu are given adequate importance and are included in the curriculum. Limited students are taken into each classes for proper training and utilization. The school follows Indian Council of Secondary Education, Delhi till Vth standard so as to lay solid foundation for his/her academics. Telangana State Govt. Syllabus will be following from Vith standard onwards so that the student can appear for Xth Class Common Board Examination. The curriculum of school is aptly supported by modern methods of teaching like multi-media computers and audio-visual aids for better and easy understanding and to enhance the children's interest and make learning an enjoyable experience for them.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Curriculum;
