import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {  useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { login } from '../actions/auth';

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading } = useSelector((state) => state.auth);

    const initialState = {
        email: "",
        password: "",
    };

    const [authData, setAuthData] = useState(initialState);

    const handleChange = (e) => {
        setAuthData({ ...authData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (Object.values(authData).includes("")) alert("Please fill all the fields");
        else dispatch(login(authData, navigate));
    };

    return (
        <>
            <br />
            <section>
                <div className="container">
                    <div className="sign-form px-auto">
                        <h2>Login</h2>
                        <form onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-lg-12">
                                    <input style={{ color: '#333' }} type="email" name="email" id="email" placeholder="Email Id" onChange={handleChange} />
                                </div>
                                <div className="col-lg-12">
                                    <input style={{ color: '#333' }} type="password" name="password" id="password" placeholder="Password" onChange={handleChange} />
                                </div>
                                <div className="offset-lg-4 col-lg-4 justify-content-center">
                                    <button type="submit" className="site-btn">Log In &nbsp; {isLoading && <CircularProgress style={{ position: 'absolute', color: 'white' }} size={25} disableShrink />}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </section>
            <br />
        </>
    );
};

export default Login;
