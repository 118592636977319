import React, { useEffect, useState } from 'react'
import CarouselComponent from './CarouselComponent'
import HomeBody from './HomeBody'


const Home = () => {

  return (
  <>
    <CarouselComponent/>
    <HomeBody/>
  </>
  )
}

export default Home
