import React, { useEffect, useState } from 'react';
import { Box, Button, Collapse, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import decode from 'jwt-decode'
import { AUTH_DATA, LOGOUT } from '../constants/auth';
import { fillAuthData } from '../actions/auth';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const Header = () => {
    const isMobile = useMediaQuery({ maxWidth: 770 })
    const navigate = useNavigate()

    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [open2, setOpen2] = useState(false)
    const [open3, setOpen3] = useState(false)
    const [open4, setOpen4] = useState(false)

    const dispatch = useDispatch()
    const location = useLocation();
    const { isLogin, authData } = useSelector((state) => state.auth)
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));

    useEffect(() => {
        setUser(JSON.parse(localStorage.getItem('profile')))
        const token = user?.token;
        // Taking care of a jwt token's expiry here
        if (token) {
            const decodedToken = decode(token)
            // console.log(decodedToken)
            // console.log(decode(token))
            if (decodedToken.exp * 1000 < new Date().getTime() || !isLogin) {
                setUser(null)
                dispatch({ type: LOGOUT })
            }
            else {
                dispatch(fillAuthData(decodedToken))
            }
        }

    }, [location])
    return (
        <>
            <header className="header">
                <div >
                    <Link to='/'>
                        <img src={process.env.PUBLIC_URL + '/img/header.jpg'} style={{ border: 'none', marginBottom:'5px' }} alt='Image' />
                    </Link>
                </div>
                <div className="header-nav">
                    {isMobile ?
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={() => setOpen(true)}
                                    sx={{
                                        ml: 1,
                                        display: { sm: 'none' },
                                        backgroundColor: '#2D2D2D', // Use primary color for background
                                        borderRadius: '12px', // Adjust the roundness here
                                    }}
                                    style={{marginBottom:'5px'}}
                                    size='small'
                                >
                                    <MenuIcon sx={{ fontSize: 25, color: '#FFFDE6' }} color='#FFFDE6' /> {/* Set text color to contrast with primary */}
                                </IconButton>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    id='my-button'
                                    autoFocus={false}
                                    onClick={() => navigate('/')}
                                    sx={{
                                        ml: 1,
                                        display: { sm: 'none' },
                                        backgroundColor: '#2D2D2D', // Use primary color for background
                                        borderRadius: '12px', // Adjust the roundness here
                                    }}
                                    style={{marginBottom:'5px'}}
                                    size='small'
                                >
                                    <HomeOutlinedIcon sx={{ fontSize: 25, color: '#FFFDE6' }} color='#FFFDE6' /> {/* Set text color to contrast with primary */}
                                </IconButton>
                            </div>


                            <Drawer
                                anchor={'left'}
                                open={open}
                                onClose={() => setOpen(false)}
                            >
                                <Box
                                    role="presentation"
                                    style={{ width: '200px', maxWidth: '200px' }}
                                >
                                    <List>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => setOpen1(!open1)}>
                                                <ListItemText primary={'Home'} />

                                                {open1 ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={open1} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/vision-mission" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen1(false)}}>
                                                        Vision & Mission
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/aims-objectives" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen1(false)}}>
                                                        Aims & Objectives
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/curriculum" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen1(false)}}>
                                                        Curriculum
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/subjects" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen1(false)}}>
                                                        Subjects
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => setOpen3(!open3)}>
                                                <ListItemText primary={'Administration'} />
                                                {open3 ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={open3} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="school-timings" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen3(false)}}>
                                                        School Timings
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="school-uniform" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen3(false)}}>
                                                        School Uniform
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/fees" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen3(false)}}>
                                                        Fee Structure
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/admission-process" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen3(false)}}>
                                                        Admission Process
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/code-of-conduct" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen3(false)}}>
                                                        Code of Conduct
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => setOpen4(!open4)}>
                                                <ListItemText primary={'Homework'} />
                                                {open4 ? <ExpandLess /> : <ExpandMore />}
                                            </ListItemButton>
                                        </ListItem>
                                        <Collapse in={open4} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/nursery" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Nursery
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/lkg" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        LKG
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/ukg" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        UKG
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-I" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class I
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-II" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class II
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-III" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class III
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-IV" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class IV
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-V" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class V
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-VI" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class VI
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-VII" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class VII
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-VIII" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class VIII
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-IX" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class IX
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                            <List component="div" disablePadding>
                                                <ListItemButton sx={{ pl: 4 }}>
                                                    <Link to="/homework/class-X" style={{ fontSize: '15px', fontWeight: '500', color: 'black' }} onClick={()=>{setOpen(false); setOpen4(false)}}>
                                                        Class X
                                                    </Link>
                                                </ListItemButton>
                                            </List>
                                        </Collapse>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => { navigate('gallery'); setOpen(false) }}>
                                                <ListItemText primary={'Gallery'} />
                                            </ListItemButton>
                                        </ListItem>
                                        {authData?.role==='0' && <ListItem disablePadding>
                                            <ListItemButton onClick={() => { navigate('signup'); setOpen(false) }}>
                                                <ListItemText primary={'Create Account'} />
                                            </ListItemButton>
                                        </ListItem>}
                                        {authData?.role==='0' && <ListItem disablePadding>
                                            <ListItemButton onClick={() => { navigate('delete-account'); setOpen(false) }}>
                                                <ListItemText primary={'Delete Account'} />
                                            </ListItemButton>
                                        </ListItem>}
                                        {isLogin && <ListItem disablePadding>
                                            <ListItemButton onClick={() => { dispatch({ type: LOGOUT }); setOpen(false);  }}>
                                                <ListItemText primary={'Logout'} />
                                            </ListItemButton>
                                        </ListItem>}
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => { navigate('staff'); setOpen(false) }}>
                                                <ListItemText primary={'Staff'} />
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => { navigate('contact-us'); setOpen(false) }}>
                                                <ListItemText primary={'Contact Us'} />
                                            </ListItemButton>
                                        </ListItem>
                                    </List>
                                </Box>
                            </Drawer>
                        </>

                        : <div className="row" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center', }}>
                            <div className="col-lg-11 col-md-11" style={{ position: 'relative', top: "-10px", display: 'flex', flexDirection: 'row', justifyContent: 'center', alignContent: 'center' }} >
                                <nav className="header__menu mobile-menu" style={{ float: 'left' }}>
                                    <ul >
                                        <li>
                                            <Link style={{ fontSize: '20px' }} to="/">Home</Link>
                                            <ul className="dropdown">
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="/vision-mission">Vision & Mission</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="/aims-objectives">Aims and Objective</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="/curriculum">Curriculum</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="/subjects">Subjects</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link style={{ fontSize: '20px' }} to="#">Administration</Link>
                                            <ul className="dropdown">
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="school-timings">School Timings</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="school-uniform">School Uniform</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="/fees">Fee Structure</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="admission-process">Admission Process</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="/code-of-conduct">Code of Conduct</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link style={{ fontSize: '20px' }} to="#">Homework</Link>
                                            <ul className="dropdown">
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/nursery">Nursery</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/lkg">LKG</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/ukg">UKG</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-I">Class I</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-II">Class II</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-III">Class III</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-IV">Class IV</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-V">Class V</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-VI">Class VI</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-VII">Class VII</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-VIII">Class VIII</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-IX">Class IX</Link></li>
                                                <li><Link style={{ fontSize: '15px', fontWeight: '500' }} to="homework/class-X">Class X</Link></li>
                                            </ul>
                                        </li>
                                        <li><Link style={{ fontSize: '20px' }} to="/gallery">Gallery</Link> </li>
                                        <li><Link style={{ fontSize: '20px' }} to="/staff">Staff</Link> </li>
                                        <li><Link style={{ fontSize: '20px' }} to="/contact-us">Contact Us</Link> </li>
                                        {authData?.role==='0' && <li><Link style={{ fontSize: '20px' }} to="/signup">Add Account</Link> </li>}
                                        {authData?.role==='0' && <li><Link style={{ fontSize: '20px' }} to="/delete-account">Remove Account</Link> </li>}
                                        {isLogin && <li><Link style={{ fontSize: '20px' }} to="#" onClick={() => { dispatch({ type: LOGOUT });  }}>Logout</Link> </li>}
                                    </ul>
                                </nav>
                            </div>
                        </div>}
                </div>
            </header>
        </>
    );
};

export default Header;