import React from 'react';

const ContactUs = () => {
  const headerStyle = {
    fontSize: '37px', // Reduced font size
    fontWeight: 'bold', // Bold font
    color: 'navy', // Navy blue color
    paddingTop: '2%',
  };

  const infoStyle = {
    fontSize: '20px',
  };

  return (
    <>
      <section>
        <div className="container">
          <h1 className="text-center mt-5" style={headerStyle}>
            CONTACT US
          </h1>
          <div className="row pt-5 text-center mb-5 ">
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h3 style={infoStyle}>Address</h3>
              <p>St. Lawrence High School<br />New Road, Chanchalguda, Hyderabad-500024.</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h3 style={infoStyle}>Phone</h3>
              <p>040-24560264/9849323530</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h3 style={infoStyle}>E-mail</h3>
              <p>sajjadsir@gmail.com</p>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12">
              <h3 style={infoStyle}>Website</h3>
              <p>www.stlawrencehighschool.com</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
