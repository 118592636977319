import { AUTH, AUTH_DATA, DELETE_ACCOUNT, END_LOADING_AUTH, LOGOUT, START_LOADING_AUTH } from "../constants/auth";



export default (state = { isLoading: false, isLogin: false,show:false, authData:{} }, action) => {
    switch (action.type) {
        case START_LOADING_AUTH:
            return {
                ...state,
                isLoading: true
            }
        case END_LOADING_AUTH:
            return {
                ...state,
                isLoading: false
            }
        case AUTH:
            localStorage.setItem('profile', JSON.stringify({ ...action.payload.data }))
            return {
                ...state,
                isLogin: true,
                authData:action.payload.decodedData
            };
        case LOGOUT:
            localStorage.removeItem("profile");
            return{
                isLoading: false, isLogin: false, authData:{}
            }
        case AUTH_DATA:
            return{
                ...state,
                authData:action.payload,
            }
        default:
            return state;
    }
}
