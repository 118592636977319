import { CREATE, FETCH_ALL, UPDATE, DELETE, START_LOADING, END_LOADING, DELETE_IMAGE } from '../constants/homework.js';
export default (state = { isLoading: false, homeworks: [{}] }, action) => {
    switch (action.type) {
        case START_LOADING:
            return {
                ...state,
                isLoading: true
            }
        case END_LOADING:
            return {
                ...state,
                isLoading: false
            }
        case DELETE:
            return {
                ...state,
                homeworks: state.homeworks.filter((homework) => homework._id !== action.payload)
            }
        case DELETE_IMAGE:
            return {
                ...state,
                homeworks: state.homeworks.map((homework) => {
                    if (homework._id !== action.payload._id) {
                        return homework;
                    }
                    else {
                        return {
                            ...homework,
                            bodyImages: homework.bodyImages.filter(
                                (image) => image.public_id !== action.payload.public_id
                            ),
                        };
                    }
                }),
            };

        case UPDATE:
            return {
                ...state,
                homeworks: state.homeworks.map((homework) => homework._id === action.payload.id ? action.payload : homework)
            }
        case FETCH_ALL:
            return {
                ...state,
                homeworks: action.payload.data
            };
        case CREATE:
            return {
                ...state,
                homeworks: [...state.homeworks, action.payload]
            };
        default:
            return state;
    }
}
