import React from 'react';

const Subjects = () => {
  const headingStyle = {
    fontSize: '36px',
    fontWeight: 'bold', // Bold font for the heading
    color: 'navy', // Navy blue color for the heading
    marginBottom: '20px', // Space between heading and content
  };

  const paragraphStyle = {
    lineHeight: '1.5', // Adjusted line spacing for content
  };

  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <h1 style={headingStyle}>SUBJECTS</h1>
      <div className="subjects-content">
        <p style={paragraphStyle}>I Language (Hindi / Urdu) - U.K.G. to X</p>
        <p style={paragraphStyle}>II Language (Telugu) - I to X</p>
        <p style={paragraphStyle}>III Language &ndash; English</p>
        <p style={paragraphStyle}>E.V.S (Environmental Studies) I and II</p>
        <p style={paragraphStyle}>General Science</p>
        <p style={paragraphStyle}>Mathematics</p>
        <p style={paragraphStyle}>Physical Sciences</p>
        <p style={paragraphStyle}>Biological Sciences</p>
        <p style={paragraphStyle}>Social Studies</p>
        <p style={paragraphStyle}>Computer Science</p>
        <p style={paragraphStyle}>Drawing</p>
        <p style={paragraphStyle}>Value Education</p>
        <p style={paragraphStyle}>General Knowledge</p>
        <p style={paragraphStyle}>Deeniyat</p>
      </div>
    </div>
  );
};

export default Subjects;
