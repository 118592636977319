import * as api from '../api'
import { ADD_FEES, DELETE_FEES, END_LOADING_FEES, GET_FEES, START_LOADING_FEES, UPDATE_FEES } from '../constants/fees';


export const getFees = () => async(dispatch) => {
    try{
        dispatch({type:START_LOADING_FEES})
        const {data} = await api.getFees();
        // console.log(data.data)
        dispatch({type:GET_FEES,payload:data});
        dispatch({type:END_LOADING_FEES})
    }
    catch(error)
    {
        console.log(error);
    }
}

export const addFees = (fees) => async(dispatch) => {
    try {
        // console.log(images)
        dispatch({type:START_LOADING_FEES})        
        const {data} = await api.addFees(fees);
        dispatch({type:ADD_FEES,payload:data.data});
        dispatch({type:END_LOADING_FEES})            
    } catch (error) {
        console.log(error)
    }
} 

export const updateFees = (id, fees) => async(dispatch) => {
    try {
        dispatch({type:START_LOADING_FEES})        
        const {data} = await api.updateFees(id, fees);
        dispatch({type:UPDATE_FEES,payload:data.data});
        dispatch({type:END_LOADING_FEES})            
    } catch (error) {
        console.log(error)
    }
} 

export const deleteFees = (_id) => async(dispatch) => {
    try {
            await api.deleteFees(_id)
            dispatch({type:DELETE_FEES,payload:_id})
    } catch (error) {
        console.log(error)
    }
}